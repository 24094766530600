import { QueryClientProvider } from "@tanstack/react-query";
// import { isVercelToolbarMounted, mountVercelToolbar, unmountVercelToolbar } from "@vercel/toolbar";
import React, { useEffect } from "react";

import { queryClient } from "../clients/QueryClient";
import { MainRouter } from "../routers/MainRouter";
import { useAuth } from "./Auth/AuthProvider";
import { DefaultLoading } from "./Loading";

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import(/* webpackChunkName: "react-query-devtools" */ "@tanstack/react-query-devtools/production").then((d) => ({
    default: d.ReactQueryDevtools,
  }))
);

export const MainApp = () => {
  const { accessToken } = useAuth();
  const [showDevtools, setShowDevtools] = React.useState(false);

  useEffect(() => {
    // @ts-expect-error calling window.toggleDevtools() will download the devtools bundle and show them
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  // useEffect(() => {
  //   if (window.location.hostname !== "localhost" && accessToken) {
  //     const [, body] = accessToken.split(".");
  //     try {
  //       const claims = JSON.parse(atob(body));
  //       if (claims?.email?.includes("Arun.George@bentley.com")) {
  //         if (!isVercelToolbarMounted()) {
  //           mountVercelToolbar();
  //         }
  //       }
  //     } catch {}
  //   }
  //   return () => {
  //     if (isVercelToolbarMounted()) {
  //       unmountVercelToolbar();
  //     }
  //   };
  // }, [accessToken]);

  return !accessToken ? (
    <div style={{ height: "100vh" }}>
      <DefaultLoading />
    </div>
  ) : (
    <QueryClientProvider client={queryClient}>
      <MainRouter />
      {showDevtools && (
        <React.Suspense fallback={null}>
          <ReactQueryDevtoolsProduction />
        </React.Suspense>
      )}
    </QueryClientProvider>
  );
};

import type { AccessToken } from "@itwin/core-bentley";
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { hasAuthParams, useAuth as useReactAuth } from "react-oidc-context";

interface AuthContextValue {
  accessToken?: AccessToken;
  signOut: () => void;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthContext = createContext<AuthContextValue>({
  signOut: async () => {},
});

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const auth = useReactAuth();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);
  const [accessToken, setAccessToken] = useState<AccessToken>();

  useEffect(() => {
    if (auth.isAuthenticated) {
      setAccessToken(`${auth.user?.token_type} ${auth.user?.access_token}`);
    }
  }, [auth]);

  // automatically sign-in
  useEffect(() => {
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && !hasTriedSignin) {
      auth.signinRedirect().catch(console.error);
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin]);

  useEffect(() => {
    // the `return` is important - addAccessTokenExpiring() returns a cleanup function
    return auth.events.addAccessTokenExpiring(async () => {
      // eslint-disable-next-line no-restricted-globals
      // if (confirm("You're about to be signed out due to inactivity. Press OK to stay signed in.")) {
      await auth.signinSilent().catch(console.error);
      // }
    });
  }, [auth, auth.events, auth.signinSilent]);

  const signOut = useCallback(async () => {
    await auth.signoutRedirect().catch(console.error);
  }, [auth]);

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthContext");
  }
  return context;
};

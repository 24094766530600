import { PageLayout } from "@itwin/itwinui-layouts-react";
import type { ThemeType } from "@itwin/itwinui-react";
import { ThemeProvider } from "@itwin/itwinui-react";
import React, { Suspense, useEffect, useState } from "react";
import { Outlet, useSearchParams } from "react-router-dom";

import { Header } from "./Header/Header";
import { DefaultLoading } from "./Loading";

export const Layout = () => {
  const [searchParams] = useSearchParams();
  const hideHeader = searchParams.get("ui") === "none" || searchParams.get("header") === "none";

  const [theme, setTheme] = useState<ThemeType>(
    (searchParams.get("theme") as ThemeType) ?? (localStorage.getItem("THEME") as ThemeType) ?? "os"
  );
  useEffect(() => {
    localStorage.setItem("THEME", theme);
  }, [theme]);

  return (
    <ThemeProvider
      theme={theme}
      themeOptions={{
        applyBackground: true,
        // highContrast: theme === "dark",
      }}
    >
      <PageLayout>
        {!hideHeader && (
          <PageLayout.Header>
            <Header
              theme={theme}
              onThemeChange={() => {
                setTheme((theme) => (theme === "light" ? "dark" : "light"));
              }}
            />
          </PageLayout.Header>
        )}
        <PageLayout.Content>
          <Suspense fallback={<DefaultLoading />}>
            <Outlet />
          </Suspense>
        </PageLayout.Content>
      </PageLayout>
    </ThemeProvider>
  );
};

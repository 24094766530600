import {
  Svg3D,
  SvgCompare,
  SvgCopy,
  SvgDelete,
  SvgFlag,
  SvgGroup,
  SvgModel,
  SvgStatusWarning,
  SvgTechnicalPreviewBw,
} from "@itwin/itwinui-icons-react";
import { useToaster } from "@itwin/itwinui-react";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

// eslint-disable @typescript-eslint/no-non-null-assertion
import { useAuth } from "../../components/Auth/AuthProvider";
import type { IModelFull } from "../../types";

interface ViewIModelActionProps {
  iTwinId?: string;
  iModelId?: string;
  forceRefresh?: () => void;
}

export const useViewIModelAction = ({ iTwinId, iModelId, forceRefresh }: ViewIModelActionProps) => {
  const navigate = useNavigate();
  const { accessToken } = useAuth();
  const toaster = useToaster();
  // toaster.setSettings({ placement: "bottom" });

  return {
    viewAction: useMemo(
      () => ({
        key: "view",
        icon: <Svg3D />,
        onClick: (iModel: unknown) => {
          const _iTwinId = iTwinId || (iModel as IModelFull).iTwinId;
          const _iModelId = iModelId || (iModel as IModelFull).id;
          navigate(`/view/itwin/${_iTwinId}/imodel/${_iModelId}`);
        },
        children: "View",
      }),
      [iModelId, iTwinId, navigate]
    ),
    savedViewsAction: useMemo(
      () => ({
        key: "saved-views",
        icon: <SvgGroup />,
        onClick: (iModel: unknown) => {
          const _iTwinId = iTwinId || (iModel as IModelFull).iTwinId;
          const _iModelId = iModelId || (iModel as IModelFull).id;
          navigate(`/saved-views/itwin/${_iTwinId}/imodel/${_iModelId}`);
        },
        children: "Saved Views",
      }),
      [iModelId, iTwinId, navigate]
    ),
    meshExportAction: useMemo(
      () => ({
        key: "mesh-export",
        icon: <SvgTechnicalPreviewBw />,
        children: "Mesh Export",
        sublabel: "Technical Preview",
        onClick: (iModel: unknown) => {
          const _iTwinId = iTwinId || (iModel as IModelFull).iTwinId;
          const _iModelId = iModelId || (iModel as IModelFull).id;
          navigate(`/mesh-export/itwin/${_iTwinId}/imodel/${_iModelId}`);
        },
      }),
      [iModelId, iTwinId, navigate]
    ),
    manageVersionsAction: useMemo(
      () => ({
        key: "manage-versions",
        icon: <SvgFlag />,
        onClick: (iModel: unknown) => {
          const _iTwinId = iTwinId || (iModel as IModelFull).iTwinId;
          const _iModelId = iModelId || (iModel as IModelFull).id;
          navigate(`/manage-versions/itwin/${_iTwinId}/imodel/${_iModelId}`);
        },
        children: "Manage Versions",
      }),
      [iModelId, iTwinId, navigate]
    ),
    viewInNavigatorBackendAction: useMemo(
      () => ({
        key: "navigator-backend",
        icon: <SvgStatusWarning />,
        onClick: (iModel: unknown) => {
          const { id, iTwinId } = iModel as IModelFull;
          navigate(`/view/itwin/${iTwinId}/imodel/${id}?backend=navigator-backend:v5`);
        },
        children: "View with Navigator Backend",
      }),
      [navigate]
    ),
    viewInDesignReviewAction: useMemo(
      () => ({
        key: "design-review",
        icon: <SvgStatusWarning />,
        onClick: (iModel: unknown) => {
          const { id, iTwinId } = iModel as IModelFull;
          navigate(`/view/design-review/itwin/${iTwinId}/imodel/${id}`);
        },
        children: "View with Design Review",
      }),
      [navigate]
    ),
    changedElementsAction: useMemo(
      () => ({
        key: "changed-elements",
        icon: <SvgCompare />,
        children: "Enable Changed Elements Tracking",
        // doesnt seem to work since fn is async
        // visible: async (iModel: unknown) => {
        //   const { id, iTwinId } = iModel as IModelFull;
        //   const { enabled } = await (
        //     await fetch(
        //       `https://${globalThis.IMJS_URL_PREFIX}api.bentley.com/changedelements/tracking?iTwinId=${iTwinId}&iModelId=${id}`,
        //       {
        //         headers: {
        //           // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        //           Authorization: accessToken!,
        //           Accept: "application/vnd.bentley.itwin-platform.v1+json",
        //         },
        //       }
        //     )
        //   ).json();
        //   return !enabled;
        // },
        onClick: async (iModel: unknown) => {
          const { id, iTwinId } = iModel as IModelFull;
          try {
            await fetch(`https://${globalThis.IMJS_URL_PREFIX}api.bentley.com/changedelements/tracking`, {
              method: "PUT",
              headers: {
                Authorization: accessToken!,
                "Content-Type": "application/json",
                Accept: "application/vnd.bentley.itwin-platform.v1+json",
              },
              body: JSON.stringify({
                enable: true,
                iTwinId,
                iModelId: id,
              }),
            });
          } catch {}
        },
      }),
      [accessToken]
    ),
    forkIModelAction: useMemo(
      () => ({
        key: "fork-imodel",
        icon: <SvgCopy />,
        children: "Fork iModel",
        onClick: async (iModel: unknown) => {
          const { id, iTwinId, name } = iModel as IModelFull;
          try {
            await fetch(`https://${globalThis.IMJS_URL_PREFIX}api.bentley.com/imodels/${id}/fork`, {
              method: "POST",
              headers: {
                Authorization: accessToken!,
                "Content-Type": "application/json",
                Accept: "application/vnd.bentley.itwin-platform.v2+json",
              },
              body: JSON.stringify({
                iTwinId,
                name: `[FORK] ${name!}`,
                description: `Fork of ${id}`,
                // preserveHistory: true, // defaults to false, which means squash
              }),
            });
            forceRefresh?.();
          } catch {}
        },
      }),
      [accessToken, forceRefresh]
    ),
    deleteIModelAction: useMemo(
      () => ({
        key: "delete-imodel",
        icon: <SvgDelete />,
        children: "Delete iModel",
        onClick: async (iModel: unknown) => {
          const { id } = iModel as IModelFull;
          try {
            await fetch(`https://${globalThis.IMJS_URL_PREFIX}api.bentley.com/imodels/${id}`, {
              method: "DELETE",
              headers: {
                Authorization: accessToken!,
                "Content-Type": "application/json",
                Accept: "application/vnd.bentley.itwin-platform.v2+json",
              },
            });
            forceRefresh?.();
          } catch {}
        },
      }),
      [accessToken, forceRefresh]
    ),
    editExtentsAction: useMemo(
      () => ({
        key: "edit-extents",
        icon: <SvgModel />,
        children: "Edit iModel Extents",
        onClick: async (iModel: unknown) => {
          const { id, iTwinId } = iModel as IModelFull;
          const appName = "imodel-extents-editor";
          const launchCmd = `itwin-studio://runAppId/${appName}?${new URLSearchParams({
            iTwinId: iTwinId!,
            iModelId: id,
            // displayName: "iModel Extents Editor", // ends up being the imodel name, not app name
          }).toString()}`;
          try {
            window.open(launchCmd, "Launch Extents Editor", "noopener");
            toaster.positive("Launching Extents Editor");
          } catch {}
        },
      }),
      [toaster]
    ),
  };
};

import { UserManager, WebStorageStateStore } from "oidc-client-ts";

// do popup signin when window.self !== window.top
export const userManager = new UserManager({
  authority: `https://${import.meta.env.IMJS_URL_PREFIX || ""}ims.bentley.com`,
  client_id: import.meta.env.IMJS_AUTH_CLIENT_CLIENT_ID ?? "",
  redirect_uri: window.location.origin,
  post_logout_redirect_uri: window.location.origin,
  scope: [
    "itwinjs", // needed for internal backends
    "general-purpose-imodeljs-backend",
    "navigator-backend",
    "itwin-platform",
  ].join(" "),
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  automaticSilentRenew: true,
  includeIdTokenInSilentRenew: true,
  monitorSession: true, // this allows cross tab login/logout detection
});

import { Text } from "@itwin/itwinui-react";
import { useQuery } from "@tanstack/react-query";
import React from "react";

import type { ITwinFull } from "../../../types";

interface GetProjectResult {
  iTwin?: ITwinFull;
}

interface SelectIModelTitleProps {
  iTwinId: string;
}

export const SelectIModelTitle = ({ iTwinId }: SelectIModelTitleProps) => {
  const { data } = useQuery<GetProjectResult>({
    queryKey: [`/itwins/${iTwinId}`],
  });

  return (
    <Text variant="title" as={"div"}>
      {data?.iTwin ? `iModels for ${data?.iTwin?.displayName}` : "\u00a0"}
    </Text>
  );
};

import { SvgImodel } from "@itwin/itwinui-icons-react";
import { ComboBox, Flex, HeaderButton, ListItem, MenuDivider, MenuItem, Popover, Surface } from "@itwin/itwinui-react";
import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { useViewIModelAction } from "../../routers/ViewRouter/IModelActions";
import type { IModelFull } from "../../types";
import { spreadIf } from "../../utils";
import { useAuth } from "../Auth/AuthProvider";

interface IModelHeaderButtonProps {
  iModelId?: string;
  iTwinId?: string;
  section?: string;
}
export const IModelHeaderButton = ({ iModelId, section, iTwinId }: IModelHeaderButtonProps) => {
  const navigate = useNavigate();

  const {
    data: { iModel },
  } = useQuery<{ iModel: IModelFull }>({
    queryKey: [`/imodels/${iModelId}`, { version: "v2", Accept: "application/vnd.bentley.itwin-platform.v2+json" }],
    initialData: {
      iModel: {
        id: "",
      },
    },
  });

  const { status, data } = useQuery({
    queryKey: [
      `/imodels/${iModel.id}/thumbnail`,
      { version: "v2", Accept: "application/vnd.bentley.itwin-platform.v2+json" },
      "blob",
    ],
    enabled: !!iModel.id,
  });

  const {
    data: { iModels: allIModels },
  } = useQuery<{ iModels: IModelFull[] }>({
    queryKey: [
      `/imodels/?iTwinId=${iTwinId}`,
      { version: "v2", Accept: "application/vnd.bentley.itwin-platform.v2+json" },
    ],
    initialData: { iModels: [] },
  });

  const { viewAction, savedViewsAction, manageVersionsAction, meshExportAction } = useViewIModelAction({
    iTwinId,
    iModelId,
  });

  const { accessToken } = useAuth();

  const isBentley = useMemo(() => {
    if (accessToken) {
      const [, body] = accessToken.split(".");
      try {
        const claims = JSON.parse(atob(body));
        return claims?.email?.includes("@bentley.com");
      } catch {
        return false;
      }
    }
  }, [accessToken]);

  return (
    <Popover
      content={
        <Surface>
          <Surface.Header>Search for an iModel</Surface.Header>
          <Surface.Body as={Flex} flexDirection="column" alignItems="start" isPadded>
            <ComboBox
              key={"imodel-search"}
              enableVirtualization={true}
              multiple={false}
              value={iModelId}
              options={allIModels.map((iModel) => {
                return {
                  value: iModel.id,
                  label: iModel.displayName ?? iModel.id,
                  disabled: iModel.id === iModelId,
                };
              })}
              onChange={(selectedIModel) => {
                navigate(`/${section}/itwin/${iTwinId}/imodel/${selectedIModel}`);
              }}
            />
            <MenuDivider />
            <ListItem actionable onClick={() => navigate(`/${section}/itwin/${iTwinId}`)}>
              <ListItem.Icon>
                <SvgImodel />
              </ListItem.Icon>
              {"View all iModels"}
              {/* <ListItem.Action href={`/${section}/itwin/${iTwinId}`}>View all iModels</ListItem.Action> */}
            </ListItem>
          </Surface.Body>
        </Surface>
      }
    >
      <HeaderButton
        key="iModel"
        name={iModel ? iModel?.displayName : "Fetching iModel"}
        description={iModel?.description}
        className={classNames(!iModel && "iui-skeleton")}
        isActive={!!iModel?.displayName && section !== "view"}
        startIcon={
          !!iModel?.displayName ? (
            <img
              src={status === "success" ? URL.createObjectURL(data as Blob) : ""}
              alt={`iModel ${iModel?.displayName} thumbnail`}
            />
          ) : undefined
        }
        menuItems={(close) => [
          ...spreadIf(
            section !== viewAction.key && (
              <MenuItem
                {...viewAction}
                onClick={() => {
                  close();
                  viewAction.onClick(undefined);
                }}
              />
            )
          ),
          ...spreadIf(
            section !== savedViewsAction.key && (
              <MenuItem
                {...savedViewsAction}
                onClick={() => {
                  close();
                  savedViewsAction.onClick(undefined);
                }}
              />
            )
          ),
          ...spreadIf(
            section !== manageVersionsAction.key && (
              <MenuItem
                {...manageVersionsAction}
                onClick={() => {
                  close();
                  manageVersionsAction.onClick(undefined);
                }}
              />
            )
          ),
          ...spreadIf(
            section !== meshExportAction.key && isBentley && (
              <MenuItem
                {...meshExportAction}
                onClick={() => {
                  close();
                  meshExportAction.onClick(undefined);
                }}
              />
            )
          ),
        ]}
      />
    </Popover>
  );
};

import { QueryClient } from "@tanstack/react-query";

import { userManager } from "./AuthClient";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: async ({ queryKey }) => {
        const [endpoint, options, responseType] = queryKey as [
          string,
          { version: string } | undefined,
          "arraybuffer" | "blob" | "formData" | "json" | "text" | undefined
        ];

        const user = await userManager.getUser();
        const accessToken = `${user?.token_type} ${user?.access_token}`;

        const response = await fetch(`https://${globalThis.IMJS_URL_PREFIX}api.bentley.com${endpoint}`, {
          headers: {
            Authorization: accessToken,
            Prefer: "return=representation",
            Accept: `application/vnd.bentley.itwin-platform.${options?.version ?? "v1"}+json`,
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        switch (responseType) {
          case "arraybuffer":
            return await response.arrayBuffer();
          case "blob":
            return await response.blob();
          case "formData":
            return await response.formData();
          case "text":
            return await response.text();
          case "json":
          default:
            return await response.json();
        }
      },
      // enabled: !!accessToken,
      retry: false,
      gcTime: 1000 * 60 * 15, // 15 mins
    },
  },
});

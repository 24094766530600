import React, { lazy, useEffect } from "react";
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider, useParams } from "react-router-dom";

import { Layout } from "../components/Layout";
import { DefaultLoading } from "../components/Loading";
import { Redirect } from "./Redirect";
import { fectchIModelsLoader, SelectIModel } from "./SelectionRouter/SelectIModel";
import { selectITwinLoader, SelectProject } from "./SelectionRouter/SelectProject";

// for redirecting from deprecated routes
const RedirectToSibling = ({ sibling }: { sibling: string }) => {
  const params = useParams();
  const newPath = `../${sibling}/${params["*"]}`;
  return <Redirect to={newPath} />;
};

// const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
//   const { data, status } = useQuery<{ permissions: string[] }>({
//     queryKey: ["/accesscontrol/itwins/78202ffd-272b-4207-a7ad-7d2b1af5dafc/permissions"],
//   });

//   return (
//     <>
//       {status === "pending" && (
//         <Loading
//           isLoading={false}
//           pastDelay={true}
//           timedOut={false}
//           error={undefined}
//           retry={() => {
//             /* no op */
//           }}
//         />
//       )}
//       {status === "error" && <Navigate to="/view" replace />}
//       {/* {status === "success" && children} */}
//       {status === "success" &&
//       ["administration_manage_roles", "ADMINACCESS"].some((permission) => data.permissions?.includes(permission)) ? (
//         children
//       ) : (
//         <Navigate to="/view" replace />
//       )}
//     </>
//   );
// };

const LazySavedViewsRouter = lazy(
  () => import(/* webpackChunkName: "SavedViewsRouter" */ "./SavedViewsRouter/SavedViewsRouter")
);

const LazyManageVersionsRouter = lazy(
  () => import(/* webpackChunkName: "ManageVersionsRouter" */ "./ManageVersionsRouter/ManageVersionsRouter")
);

const LazyManageMembersRouter = lazy(
  () => import(/* webpackChunkName: "ManageMembersRouter" */ "./MembersRouter/Router")
);

const LazyMeshExportsRouter = lazy(
  () => import(/* webpackChunkName: "MeshExportsRouter" */ "./MeshExportRouter/Router")
);

const LazyCesiumRouter = lazy(() => import(/* webpackChunkName: "CesiumRouter" */ "./CesiumRouter/CesiumRouter"));

// const LazyStudioAppStoreRouter = Loadable({
//   loader: () => import(/* webpackChunkName: "StudioAppStoreRouter" */ "./Studio/StudioStoreRouter"),
//   loading: Loading,
// });

const ArcGisOauthRedirect = () => {
  console.log("ArcGisOauthRedirect");
  useEffect(() => {
    if (window.opener) {
      const opener = window.opener;
      if (opener?.arcGisOAuth2Callback) {
        opener.arcGisOAuth2Callback(window.location);
      } else {
        console.log("ERROR: arcGisOAuth2Callback is not defined");
      }
    }
  }, []);

  return (
    <div style={{ height: "100vh" }}>
      <DefaultLoading />
    </div>
  );
};

const router = createBrowserRouter(
  [
    ...createRoutesFromElements(
      <Route element={<Layout />}>
        <Route path="view/cesium/*" element={<LazyCesiumRouter />} />
        {/* <Route path="view/*" element={<ViewRouter />} /> */}
        <Route path="members/*" element={<LazyManageMembersRouter />} />
        <Route path="saved-views/*" element={<LazySavedViewsRouter />} />
        <Route path="manage-versions/*" element={<LazyManageVersionsRouter />} />
        <Route path="mesh-export/*" element={<LazyMeshExportsRouter />} />
        {/* Protected Route that check for permissions before rendering the children */}
        {/* <Route
          path="mango"
          element={
            <ProtectedRoute>
              <LazyStudioAppStoreRouter />
            </ProtectedRoute>
          }
        /> */}
      </Route>
    ),
    {
      path: "esri-oauth2-callback",
      element: <ArcGisOauthRedirect />,
    },
    {
      // index: true,
      element: <Layout />,
      children: [
        {
          path: "/",
          element: <Redirect to="/view" />,
        },
        {
          path: "view/*",
          children: [
            {
              path: "*",
              id: "iTwin-selector",
              element: <SelectProject />,
              loader: selectITwinLoader,
            },
            {
              path: "itwin/:iTwinId/imodel?",
              id: "iModel-selector",
              element: <SelectIModel />,
              loader: fectchIModelsLoader,
            },
            ...[
              "itwin/:iTwinId/imodel/:iModelId",
              "itwin/:iTwinId/imodel/:iModelId/changeset/:changesetId",
              "itwin/:iTwinId/imodel/:iModelId/version/:namedVersionId",
              "itwin/:iTwinId/imodel/:iModelId/savedview/:savedviewId",
              "itwin/:iTwinId/imodel/:iModelId/mesh-export/:meshExportId",
            ].map((path) => ({
              path,
              async lazy() {
                const { View } = await import("./ViewRouter/Viewer");
                return { Component: View };
              },
            })),
            {
              path: "design-review/itwin/:iTwinId/imodel/:iModelId",
              async lazy() {
                const { DesignReviewViewer } = await import("./ViewRouter/ViewRouter");
                return { Component: DesignReviewViewer };
              },
            },
          ],
        },
      ],
    },
  ],
  {
    future: {
      v7_relativeSplatPath: true,
    },
  }
);

export const MainRouter = () => {
  return (
    <RouterProvider
      router={router}
      fallbackElement={
        <div style={{ height: "100vh" }}>
          <DefaultLoading />
        </div>
      }
      future={{
        v7_startTransition: true,
      }}
    />
  );
};

import { SvgFlag } from "@itwin/itwinui-icons-react";
import { HeaderButton, MenuDivider, MenuItem } from "@itwin/itwinui-react";
import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";

interface IdAndDisplay {
  id: string;
  displayName?: string;
}

interface VersionHeaderButtonProps {
  versionId?: string;
  iModelId?: string;
  iTwinId?: string;
  section?: string;
}
export const VersionHeaderButton = ({ iModelId, section, iTwinId, versionId }: VersionHeaderButtonProps) => {
  const navigate = useNavigate();
  const {
    data: { namedVersion: fetchedVersion },
  } = useQuery<{ namedVersion: IdAndDisplay }>({
    queryKey: [
      `/imodels/${iModelId}/namedversions/${versionId}`,
      { version: "v2", Accept: "application/vnd.bentley.itwin-platform.v2+json" },
    ],
    initialData: {
      namedVersion: {
        id: "",
      },
    },
    enabled: !!versionId,
  });

  const {
    data: { namedVersions: fetchedVersions },
  } = useQuery<{ namedVersions: IdAndDisplay[] }>({
    queryKey: [
      `/imodels/${iModelId}/namedversions?$top=5&$orderBy=changesetIndex%20desc`,
      { version: "v2", Accept: "application/vnd.bentley.itwin-platform.v2+json" },
    ],
    initialData: {
      namedVersions: [],
    },
  });

  const displayName = versionId ? fetchedVersion?.displayName : "Latest version";
  return section === "view" && (versionId || fetchedVersions?.length) ? (
    <HeaderButton
      key="namedVersion"
      name={displayName ?? "Fetching version"}
      className={classNames(!displayName && "iui-skeleton")}
      isActive={!!displayName}
      startIcon={<SvgFlag />}
      menuItems={(close) => [
        ...(fetchedVersions?.map((version) => (
          <MenuItem
            key={version.id}
            onClick={() => {
              close();
              navigate(`/view/itwin/${iTwinId}/imodel/${iModelId}/version/${version.id}`);
            }}
          >
            {version.displayName}
          </MenuItem>
        )) ?? []),
        <MenuDivider key={"menu-divider"} />,
        <MenuItem
          key={"versions"}
          onClick={() => {
            close();
            navigate(`/manage-versions/itwin/${iTwinId}/imodel/${iModelId}`);
          }}
        >
          All versions...
        </MenuItem>,
      ]}
      as={"button"}
    />
  ) : null;
};

import { SvgItwin, SvgStatusWarning } from "@itwin/itwinui-icons-react";
import {
  ComboBox,
  Flex,
  HeaderButton,
  Icon,
  MenuDivider,
  MenuItem,
  Popover,
  Surface,
  Tooltip,
} from "@itwin/itwinui-react";
import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";

import type { ITwinFull } from "../../types";

interface ProjectHeaderButtonProps {
  isActive?: boolean;
  iTwinId?: string;
  section?: string;
}
export const ProjectHeaderButton = ({ iTwinId, isActive, section }: ProjectHeaderButtonProps) => {
  const navigate = useNavigate();

  const {
    data: { iTwin: selectedITwin },
  } = useQuery<{ iTwin: ITwinFull }>({
    queryKey: [`/itwins/${iTwinId}`],
    initialData: {
      iTwin: {
        id: "",
      },
    },
  });

  const { status, data: iTwinThumbnail } = useQuery<{
    image: {
      id: string;
      smallImageName: string;
      smallImageUrl: string;
      largeImageName: string;
      largeImageUrl: string;
    };
  }>({
    queryKey: [`/itwins/${selectedITwin.id}/image`],
    enabled: !!selectedITwin.id,
    retry: false,
  });

  const { data: primaryAccount } = useQuery<{ iTwin?: ITwinFull }>({
    queryKey: ["/itwins/myprimaryaccount"],
  });

  const {
    data: { iTwins: recentITwins },
  } = useQuery<{ iTwins: ITwinFull[] }>({
    queryKey: ["/itwins/recents?subClass=Project&$top=5"],
    initialData: { iTwins: [] },
  });

  const {
    data: { iTwins: allITwins },
  } = useQuery<{ iTwins?: ITwinFull[] }>({
    queryKey: [`/itwins/?subClass=Project&includeInactive=true`],
    initialData: { iTwins: [] },
  });

  return (
    <Popover
      content={
        <Surface>
          <Surface.Header>Search for an iTwin</Surface.Header>
          <Surface.Body as={Flex} flexDirection="column" alignItems="start" isPadded>
            <ComboBox
              enableVirtualization={true}
              multiple={false}
              value={selectedITwin.id}
              options={allITwins!.map((iTwin) => {
                return {
                  value: iTwin.id,
                  label: iTwin.displayName ?? iTwin.id,
                  disabled: iTwin.id === selectedITwin.id,
                };
              })}
              onChange={(selectedITwin) => {
                navigate(`/${section}/itwin/${selectedITwin}`);
              }}
            />
          </Surface.Body>
        </Surface>
      }
    >
      <HeaderButton
        key="project"
        name={selectedITwin ? selectedITwin?.displayName : "Fetching iTwins..."}
        description={selectedITwin?.number}
        className={classNames(!selectedITwin && "iui-skeleton")}
        isActive={!!selectedITwin?.displayName && isActive}
        startIcon={
          iTwinThumbnail && !!selectedITwin?.displayName ? (
            <img
              src={status === "success" && iTwinThumbnail ? iTwinThumbnail.image.smallImageUrl : ""}
              alt={`${selectedITwin?.displayName} iTwin thumbnail`}
            />
          ) : (
            <SvgItwin />
          )
        }
        endIcon={
          primaryAccount?.iTwin?.id !== selectedITwin?.iTwinAccountId ? (
            <Tooltip content="External iTwin">
              <Icon fill="warning">
                <SvgStatusWarning />
              </Icon>
            </Tooltip>
          ) : undefined
        }
        menuItems={
          !!selectedITwin?.displayName
            ? (close) => [
                ...(recentITwins?.map((iTwin) => (
                  <MenuItem
                    key={iTwin.id}
                    onClick={() => {
                      close();
                      navigate(`/${section}/itwin/${iTwin.id}`);
                    }}
                  >
                    {iTwin.displayName}
                  </MenuItem>
                )) ?? []),
                <MenuDivider key={"menu-divider"} />,
                <MenuItem
                  key={"favorites"}
                  onClick={() => {
                    close();
                    navigate(`/${section}`);
                  }}
                >
                  All favorite iTwins...
                </MenuItem>,
                <MenuItem
                  key={"recents"}
                  onClick={() => {
                    close();
                    navigate(`/${section}?recents`);
                  }}
                >
                  All recent iTwins...
                </MenuItem>,
                <MenuItem
                  key={"others"}
                  onClick={() => {
                    close();
                    navigate(`/${section}?myprojects`);
                  }}
                >
                  All my iTwins...
                </MenuItem>,
              ]
            : undefined
        }
      />
    </Popover>
  );
};

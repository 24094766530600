import { SvgIModelLoader } from "@itwin/itwinui-illustrations-react";
import React from "react";

export const DefaultLoading = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <SvgIModelLoader style={{ height: "64px" }} />
    </div>
  );
};

import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import React from "react";

import { AuthProvider } from "./components/Auth/AuthProvider";
import { MainApp } from "./components/MainApp";
import { isLocalhost } from "./utils";

const App: React.FC = () => {
  return (
    <>
      <AuthProvider>
        <MainApp />
      </AuthProvider>
      {!isLocalhost && (
        <>
          <Analytics />
          <SpeedInsights />
        </>
      )}
    </>
  );
};

export default App;

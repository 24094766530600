import { useMatch } from "react-router-dom";

export const useCommonPathPattern = () => {
  /**
   * These needs to be done separately because match will not detect partial successes
   * `/:section/itwin/:iTwinId/imodel/:iModelId` will return null if used with url
   * "/view/itwin/123", for example.
   */

  const isCesiumView = useMatch("/:section/cesium/*") != null;
  const sectionMatch = useMatch("/:section/*");
  const projectMatch = useMatch(`/:section${isCesiumView ? "/cesium" : ""}/itwin/:iTwinId/*`);
  const iModelMatch = useMatch(`/:section${isCesiumView ? "/cesium" : ""}/itwin/:iTwinId/imodel/:iModelId/*`);
  return {
    section: sectionMatch?.params.section,
    iTwinId: projectMatch?.params?.iTwinId,
    iModelId: iModelMatch?.params.iModelId,
  };
};
